import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class NewWebsiteGuard implements CanActivate {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.production) {
      window.location.href = environment.newWebsiteUrl;
      return false;
    } else {
      return true;
    }
  }
}
