<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>

                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <!-- <a href="https://goo.gl/maps/x1wxfmeSfjm73Shj9" target="_blank">64/3834,Ujjaini,
                                Vyloppilly lane, Azad Road, Kaloor, Cochin, Kerala.</a> -->
                                <a routerLink="/contact">Kochi | Bengaluru</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+9176767 42181">+91 76767 42181</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@thesmartowl.co">hello@thesmartowl.co</a>
                        </li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/thesmartowlco/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                        <li><a href="https://instagram.com/thesmartowlco?igshid=73dhrdbxvln5" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/the-smart-owl" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Policies</h3>

                    <ul class="support-link">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/refund-policy">Refund Policy</a></li>
                        <!-- <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>

                    <ul class="useful-link">
                        <!-- <li><a routerLink="/faqs">FAQ's</a></li> -->
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>

                    <div class="newsletter-box">
                        <p>To get the latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div> -->
            <p><i class='bx bx-copyright'></i>2020 <a routerLink="/" target="_blank">SmartOwl</a> Designed By <a href="https://crediblefuture.com/" target="_blank">Credible Future</a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>