import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./common/footer/footer.component";
import { PreloaderComponent } from "./common/preloader/preloader.component";
import { ServiceService } from "./provider/service.service";
// import { RefundComponent } from './page/refund/refund.component';
// import { ForgotComponent } from './page/forgot/forgot.component';
// import { PaypalComponent } from './page/paypal/paypal.component';
// import { LiveComponent } from './page/live/live.component';
// import { BusinessComponent } from './page/business/business.component';
// import { TermsComponent } from './page/terms/terms.component';
// import { PrivacyComponent } from './page/privacy/privacy.component';
// import { ComingsoonComponent } from './page/comingsoon/comingsoon.component';
// import { GradeComponent } from './page/grade/grade.component';
// import { TutorsComponent } from './page/tutors/tutors.component';
// import { BecomeTutorComponent } from './page/become-tutor/become-tutor.component';
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpInterceptorService } from "./shared/sharedService/httpInterceptor";
import { SsoRedirectComponent } from "./page/ssoredirect";
import { NewWebsiteGuard } from "./_guards/newwebsite.guard";
import { SafePipe } from "./pipes/safe.pipe";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PreloaderComponent,
    SsoRedirectComponent,
    // RefundComponent, GradeComponent, TutorsComponent, BecomeTutorComponent, PrivacyComponent, TermsComponent, BusinessComponent, LiveComponent, PaypalComponent,ForgotComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 3,
    }),
  ],
  providers: [
    NewWebsiteGuard,
    ServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
