import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SsoRedirectComponent } from "./page/ssoredirect";
import { NewWebsiteGuard } from "./_guards/newwebsite.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../app/page/home/home.module").then((m) => m.HomeModule),
    canActivate: [NewWebsiteGuard],
  },
  { path: "sso-redirect/:data", component: SsoRedirectComponent },
  {
    path: "live/:data",
    loadChildren: () =>
      import("../app/page/live/live.module").then((m) => m.LiveModule),
  },
  {
    path: "student",
    loadChildren: () =>
      import("../app/page/student/student.module").then((m) => m.StudentModule),
  },
  {
    path: "course/:data",
    loadChildren: () =>
      import("../app/page/course/course.module").then((m) => m.CourseModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("../app/page/error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "tutors",
    loadChildren: () =>
      import("../app/page/tutors/tutors.module").then((m) => m.TutorsModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("../app/page/login/login.module").then((m) => m.LoginModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "register",
    loadChildren: () =>
      import("../app/page/register/register.module").then(
        (m) => m.RegisterModule
      ),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "about",
    loadChildren: () =>
      import("../app/page/about/about.module").then((m) => m.AboutModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "faqs",
    loadChildren: () =>
      import("../app/page/faq/faq.module").then((m) => m.FaqModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "contact",
    loadChildren: () =>
      import("../app/page/contact/contact.module").then((m) => m.ContactModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "career-guide",
    loadChildren: () =>
      import("../app/page/blog/blog.module").then((m) => m.BlogModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "university",
    loadChildren: () =>
      import("../app/page/university/university.module").then(
        (m) => m.UniversityModule
      ),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "become-tutor",
    loadChildren: () =>
      import("../app/page/become-tutor/become-tutor.module").then(
        (m) => m.BecomeTutorModule
      ),
    canActivate: [NewWebsiteGuard],
  },

  {
    path: "comingsoon",
    loadChildren: () =>
      import("../app/page/comingsoon/comingsoon.module").then(
        (m) => m.ComingsoonModule
      ),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("../app/page/privacy/privacy.module").then((m) => m.PrivacyModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("../app/page/terms/terms.module").then((m) => m.TermsModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "business",
    loadChildren: () =>
      import("../app/page/business/business.module").then(
        (m) => m.BusinessModule
      ),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "forgot_password/:data",
    loadChildren: () =>
      import("../app/page/forgot/forgot.module").then((m) => m.ForgotModule),
  },
  {
    path: "refund-policy",
    loadChildren: () =>
      import("../app/page/refund/refund.module").then((m) => m.RefundModule),
    canActivate: [NewWebsiteGuard],
  },
  {
    path: "**",
    loadChildren: () =>
      import("../app/page/home/home.module").then((m) => m.HomeModule),
    canActivate: [NewWebsiteGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
