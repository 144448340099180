import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: "ssoredirect.component.html",
  styleUrls: ["./ssoredirect.component.css"],
})
export class SsoRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    const navParams = JSON.parse(
      decodeURI(atob(this.route.snapshot.paramMap.get("data")))
    );
    localStorage.removeItem('activeUser');
    localStorage.removeItem('currentUser');
    localStorage.setItem("activeUser", JSON.stringify(navParams.userData));
    this.router.navigate([navParams.redirectPath]);
  }
}
