<app-preloader></app-preloader>
<router-outlet></router-outlet>
<!-- <app-footer
  *ngIf="
    !(
      location == '/' ||
      location == '/login' ||
      location == '/register' ||
      location == '/coming-soon'
    )
  "
></app-footer> -->
