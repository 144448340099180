import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  api: any;
  constructor(private http: HttpClient) {}

  postData(credentials, type) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      this.http
        .post(environment.apiUrl + "/" + type, JSON.stringify(credentials), {
          headers: headers,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  payService(credentials) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      this.http
        .post(
          environment.apiBasePath + "/payu_process.php",
          JSON.stringify(credentials),
          { headers: headers }
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  accountService(credentials, type) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      this.http
        .post(
          environment.apiBasePath + "/accounts.php/" + type,
          JSON.stringify(credentials),
          {
            headers: headers,
          }
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
